













































































































































































import { promiseHelper } from '@/helpers/promise-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { IdoPoolSwapViewModel } from '../viewmodels/ido-pool-swap-viewmodel'

@Observer
@Component({})
export default class IdoPoolDSwap extends Vue {
  @Provide() vm = new IdoPoolSwapViewModel()

  tab = null
  bnbCost = ''
  amountToken = ''
  error = ''
  fee = '0'
  totalPay = '0'

  dialog = false
  resolve?: (any) => void

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    this.vm.loadPool(this.$route.params.poolid)
    return new Promise(r => (this.resolve = r))
  }
  async cancel() {
    this.vm.cancelInterval()
    this.resolve && this.resolve(null)
    this.dialog = false
    await promiseHelper.delay(100)
    this.error = ''
    this.bnbCost = '0'
    this.amountToken = '0'
  }

  async approve() {
    try {
      this.error = ''
      await this.vm.approve()
    } catch (error) {
      this.error = error.message
    }
  }

  async swap() {
    try {
      this.error = ''
      await this.vm.swap(this.amountToken)
      this.bnbCost = '0'
      this.amountToken = '0'
    } catch (error) {
      this.error = error.message
    }
  }

  maxBnb() {
    if (this.vm.tax) {
      this.bnbCost = this.vm.maxRemainPurchaseBnb
        .divUnsafe(
          FixedNumber.from('1').addUnsafe(FixedNumber.from(`${this.vm.tax}`).divUnsafe(FixedNumber.from('100')))
        )
        .toString()
    } else {
      this.bnbCost = this.vm.maxRemainPurchaseBnb + ''
    }
    this.userChangeBnbCost(this.bnbCost)
  }

  userChangeBnbCost($event) {
    this.error = ''
    const amountToken = this.vm.calculateAmountToken($event)
    this.amountToken = amountToken.toString()
    this.getTotalPay()
  }

  async userChangeAmountToken($event) {
    this.error = ''
    const bnbCost = await this.vm.calculateBnbCost($event)
    this.bnbCost = bnbCost.toString()
    this.getTotalPay()
  }

  getTotalPay() {
    if (this.vm.tax) {
      try {
        const bnbCost = FixedNumber.from(this.bnbCost)
        const fee = bnbCost.mulUnsafe(FixedNumber.from(`${this.vm.tax}`)).divUnsafe(FixedNumber.from('100'))
        const totalPay = bnbCost.addUnsafe(fee)

        this.fee = fee.toString()
        this.totalPay = totalPay.toString()
      } catch (error) {
        this.fee = '0'
        this.totalPay = '0'
      }
    }
  }
}
